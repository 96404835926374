import axios from "axios";

import store from "../state/store";

const BASEAPI_URL = "https://api-test.takipkira.com/api/";
// const BASEAPI_URL = "http://localhost:3001/api/";
const api = axios.create({
  baseURL: BASEAPI_URL,
});


api.interceptors.request.use(
  (config) => {
    const loader = document.getElementById("preloader");
    let user = JSON.parse(sessionStorage.getItem("user"));
    if (user && user.token) {
      config.headers["Authorization"] = `Bearer ${user.token}`;
    }
    if (loader) {
      loader.style.opacity = "1";
      loader.style.visibility = "visible";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function handleResponse(response) {
  const loader = document.getElementById("preloader");
  if (loader) {
    loader.style.opacity = "0";
    loader.style.visibility = "hidden";
  }
  return response.data;
}

function handleError(error) {
  const loader = document.getElementById("preloader");
  if (loader) {
    loader.style.opacity = "0";
    loader.style.visibility = "hidden";
  }
  if (error.response) {
    if (error.response.status === 400) {
      store.dispatch("notification/error", error.response.data.message, {
        root: true,
      });
    } else {
      store.dispatch("notification/error", error.response.data.message, {
        root: true,
      });
    }
  }
  return Promise.reject(error);
}

export const baseService = {
  BASEAPI_URL,
  api,
  handleResponse,
  handleError,
};
