import { baseService } from "./base.service";
export const userService = {
  login,
  verify2FA,
  forgotPassword,
  resetPassword,
  getUsers,
  addUser,
  getUserById,
  updateUserById,
  deleteUserById,

  getMyProfile,
  updateMyProfile,
  updatePassword,

  getMySettings,
  updateMySettings,

  getUserContacts,
  addContact,
  deleteContact,

  exportUser,

  getAggrement,
  setAggrement
};

function login(email, password) {
  return baseService.api
    .post("/v1/auth", { loginKey: email, password })
    .then(baseService.handleResponse)
    .then((response) => {
      if (response.user && response.twoFactor == false) {
        sessionStorage.setItem("user", JSON.stringify(response));
      }
      return response;
    })
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function verify2FA(code, secureCode) {
  return baseService.api
    .post("/v1/auth/otp", { code, secureCode })
    .then(baseService.handleResponse)
    .then((response) => {
      if (response.user) {
        sessionStorage.setItem("user", JSON.stringify(response));
      }
      return response;
    })
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function forgotPassword(email) {
  return baseService.api
    .post("/v1/auth/forgot-password", { email })
    .then(baseService.handleResponse)
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function resetPassword(secretCode, code, password, rePassword) {
  return baseService.api
    .post("/v1/auth/reset-password", { secretCode, code, password, rePassword })
    .then(baseService.handleResponse)
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function getUsers(role, perPage = 100, page = 1, search = null) {
  const url = new URL('/api/v1/users', baseService.BASEAPI_URL);
  if (role) {
    url.searchParams.append('role', role);
  }
  if (perPage) {
    url.searchParams.append('perPage', perPage);
  }

  if (page) {
    url.searchParams.append('page', page);
  }

  if (search) {
    url.searchParams.append('search', search);
  }

  return baseService.api
    .get(url.toString())
    .then(baseService.handleResponse)
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function addUser(user) {
  return baseService.api
    .post("/v1/users", user)
    .then(baseService.handleResponse)
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function getUserById(id) {
  return baseService.api
    .get(`/v1/users/${id}`)
    .then(baseService.handleResponse)
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function updateUserById(id, user) {
  return baseService.api
    .patch(`/v1/users/${id}`, user)
    .then(baseService.handleResponse)
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function getMyProfile() {
  return baseService.api
    .get("/v1/users/my-profile")
    .then(baseService.handleResponse)
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function updateMyProfile(user) {
  return baseService.api
    .put("/v1/users/my-profile", user)
    .then(baseService.handleResponse)
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function updatePassword(user) {
  return baseService.api
    .post("/v1/auth/change-password", user)
    .then(baseService.handleResponse)
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function deleteUserById(id) {
  return baseService.api
    .delete(`/v1/users/${id}`)
    .then(baseService.handleResponse)
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function getMySettings() {
  return baseService.api
    .get("/v1/users/settings")
    .then(baseService.handleResponse)
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function updateMySettings(user) {
  return baseService.api
    .put("/v1/users/settings", user)
    .then(baseService.handleResponse)
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function getUserContacts(userId) {
  return baseService.api
    .get("/v1/users/" + userId + "/contracts")
    .then(baseService.handleResponse)
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function addContact(userId, contact) {
  return baseService.api
    .post("/v1/users/" + userId + "/contracts", contact)
    .then(baseService.handleResponse)
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function deleteContact(userId, contactId) {
  return baseService.api
    .delete("/v1/users/" + userId + "/contracts/" + contactId)
    .then(baseService.handleResponse)
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function exportUser() {
  return baseService.api
    .get("/v1/export/users")
    .then(baseService.handleResponse)
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function getAggrement() {
  return baseService.api
    .get("/v1/users/aggrement")
    .then(baseService.handleResponse)
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function setAggrement() {
  return baseService.api
    .put("/v1/users/aggrement",{ aggrement: true})
    .then(baseService.handleResponse)
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}