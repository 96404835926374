import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
          next();
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("../views/logout.vue")
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/forgot-password.vue"),
    meta: {
      title: "Şifremi Unuttum",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/home/index.vue"),
  },
  {
    path: "/users",
    name: "users",
    meta: {
      title: "Kullanıcılar",
      authRequired: true,
    },
    component: () => import("../views/users.vue"),
  },
  {
    path: "/users/:id",
    name: "user-details",
    meta: {
      title: "Kullanıcı Detayı",
      authRequired: true,
    },
    component: () => import("../views/user_detail.vue"),
  },
  {
    path: "/properties",
    name: "properties",
    meta: {
      title: "Mülkler",
      authRequired: true,
    },
    component: () => import("../views/properties.vue"),
  },
  {
    path: "/properties/:id",
    name: "properties-details",
    meta: {
      title: "Mülk Detayı",
      authRequired: true,
    },
    component: () => import("../views/propertie_detail.vue"),
  },
  {
    path: "/ads",
    name: "ads",
    meta: {
      title: "Fırsat Yatırımlar",
      authRequired: true,
    },
    component: () => import("../views/ads.vue"),
  },
  {
    path: "/ads/:id",
    name: "ads_details",
    meta: {
      title: "Fırsat Yatırımlar",
      authRequired: true,
    },
    component: () => import("../views/ads_detail.vue"),
  },
  {
    path: "/institutions",
    name: "institutions",
    meta: {
      title: "Kurumlar",
      authRequired: true,
    },
    component: () => import("../views/institutions.vue"),
  },
  {
    path: "/announcements",
    name: "announcements",
    meta: {
      title: "Duyurular",
      authRequired: true,
    },
    component: () => import("../views/announcements.vue"),
  },
  {
    path: "/my-properties",
    name: "my-properties",
    meta: {
      title: "Mülklerim",
      authRequired: true,
    },
    component: () => import("../views/my_properties.vue"),
  },
  {
    path: "/my-properties/:id",
    name: "my-properties-details",
    meta: {
      title: "Mülk Detayı",
      authRequired: true,
    },
    component: () => import("../views/my_propertie_detail.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    meta: {
      title: "Profilim",
      authRequired: true,
    },
    component: () => import("../views/profile.vue"),
  },
  {
    path: "/calendar",
    name: "calendar",
    meta: { title: "Takvim", authRequired: true },
    component: () => import("../views/calendar.vue"),
  },
  {
    path: "/accounting",
    name: "accounting",
    meta: { title: "Şirket Muhasebesi", authRequired: true },
    component: () => import("../views/accounting.vue"),
  },
  {
    path: "/notifications",
    name: "notifications",
    meta: { title: "Bildirimler", authRequired: true },
    component: () => import("../views/notifications.vue"),
  },
  {
    path: "/payment-request",
    name: "payment-request",
    meta: { title: "Ödeme Talepleri", authRequired: true },
    component: () => import("../views/payments.vue"),
  },
  {
    path: "/document-generator",
    name: "document-generator",
    meta: { title: "Sözleşme Oluşturma", authRequired: true },
    component: () => import("../views/documents/index.vue"),
  }
];